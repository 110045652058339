import React from 'react';
import { useTranslation } from 'react-i18next';

import { deleteChatRoom } from '../../../api/services/messages/requests';

import { formatPhoneNumber } from '../../../common/utils/helpers';

import { EUserRole } from '../../../common/types/dto/user';
import { IChatParticipant } from '../types/chat';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

import useWebsocketChatStore from '../stores/useWebsocketChatStore';

import BurgerDropdown from '../../../common/components/Dropdown/DropdownSimple';
import ImageWithFallback from '../../../common/components/Image/ImageWithFallback';

import MenuIcon from '../../../common/assets/icons/MenuIcon';
import ThrashCan from '../../../common/assets/icons/ThrashCan';

interface ChatRoomHeaderProps {
  currentRoomOpponent: IChatParticipant;
}

const ChatRoomHeader: React.FC<ChatRoomHeaderProps> = ({
  currentRoomOpponent,
}) => {
  const { t } = useTranslation();

  const { loader, setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { roomId, resetStore } = useWebsocketChatStore();

  const handleChatDelete = async () => {
    try {
      setLoader(true);
      if (currentRoomOpponent.role === EUserRole.Admin) return;

      await deleteChatRoom(roomId);
      resetStore();
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false);
    }
  };
  const opponentName =
    currentRoomOpponent?.role === EUserRole.Admin
      ? t('chat_support_name')
      : currentRoomOpponent?.name;

  return (
    <div className="flex justify-between items-center gap-2">
      <div className="flex items-center gap-[12px]">
        <div>
          {currentRoomOpponent?.avatar?.url ? (
            <ImageWithFallback
              src={currentRoomOpponent.avatar.url}
              width={50}
              height={50}
              alt="user icon"
              className="h-[50px] w-[50px] rounded-[12px] object-cover"
            />
          ) : (
            <div className="flex-center rounded-[12px] w-[50px] h-[50px] bg-primary text-white">
              {opponentName?.charAt(0)}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <div className="text-200 text-heading font-bold">{opponentName}</div>
          <div className="text-body-smaller text-heading">
            {currentRoomOpponent?.email}
            {currentRoomOpponent?.phone
              ? ` | ${formatPhoneNumber(currentRoomOpponent.phone)}`
              : ''}
          </div>
        </div>
      </div>
      {currentRoomOpponent?.role !== EUserRole.Admin && (
        <BurgerDropdown
          title={<MenuIcon />}
          className="w-[18px] h-[18px] mr-[10px] cursor-pointer"
          menuClass="left-auto right-0"
        >
          <button
            onClick={handleChatDelete}
            disabled={loader}
            className="flex items-center gap-[10px] bg-white p-[10px] rounded-[10px] shadow-[0_4px_10px_0_#0000000D]"
          >
            <div>
              <ThrashCan />
            </div>
            <span className="text-[14px] whitespace-nowrap">
              {t('delete_chat')}
            </span>
          </button>
        </BurgerDropdown>
      )}
    </div>
  );
};

export default ChatRoomHeader;
