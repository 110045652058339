import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  patchUpdateAgent,
  patchUpdateClient,
} from '../../../api/services/user/requests';

import { IPatchUpdateAgentPayload } from '../../../api/services/user/types';
import { EUserRole } from '../../../common/types/dto/user';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';
import useAuthStore from '../../../common/stores/useAuthStore';
import useNotificationStore from '../../../common/stores/useNotificationStore';

const useFormNotificationSettings = () => {
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const { user, setUser } = useAuthStore();
  const { addNotification } = useNotificationStore();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: {
      notificationSettings: {
        chatMessage: false,
        changeStatuses: true,
        service: true,
        savedSearches: false,
        promotions: true,
      },
    },
    mode: 'onChange',
  });

  const onSubmit = async (value: any) => {
    setLoader(true);
    // let avatarData;
    // if (avatar?.toUpload) {
    //   try {
    //     avatarData = await postMediaUploadImage({ image: avatar.file });
    //   } catch (error: any) {
    //     setError(error.response.data.message);
    //     setLoader(false);
    //     return;
    //   }
    // } else {
    //   avatarData = avatar;
    // }

    const requestFunction =
      user?.role === EUserRole.Agent ? patchUpdateAgent : patchUpdateClient;

    try {
      setLoader(true);
      const user = await requestFunction(value as IPatchUpdateAgentPayload);
      setUser(user);
      addNotification(t('update_success'), 'success');
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  };
};

export default useFormNotificationSettings;
