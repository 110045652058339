import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

import { editAccountFormSchema } from '../validations/schema';

import {
  patchUpdateAgent,
  patchUpdateClient,
} from '../../../api/services/user/requests';

import {
  IPatchUpdateAgentPayload,
  IPatchUpdateClientPayload,
} from '../../../api/services/user/types';
import { EUserRole } from '../../../common/types/dto/user';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';
import useAuthStore from '../../../common/stores/useAuthStore';
import useNotificationStore from '../../../common/stores/useNotificationStore';

const useFormEditAccount = () => {
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const { user, setUser } = useAuthStore();
  const { addNotification } = useNotificationStore();
  const { t } = useTranslation();

  const defaultValues = {
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    about: '',
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<any>({
    mode: 'onTouched',
    defaultValues,
    resolver: zodResolver(editAccountFormSchema),
  });

  // 🛠 Заполняем поля, когда `user` загружается
  useEffect(() => {
    if (user) {
      setValue('name', user.name || '');
      setValue('email', user.email || '');
      setValue('phone', user.phone || '');
    }
  }, [user, setValue]);

  const watchedName = watch('name');
  const isNameChanged = watchedName !== user?.name;

  const onSubmit = async (value: any) => {
    setLoader(true);
    const requestFunction =
      user?.role === EUserRole.Agent ? patchUpdateAgent : patchUpdateClient;

    const payload: Partial<
      IPatchUpdateAgentPayload | IPatchUpdateClientPayload
    > = {
      phone: value.phone,
      ...(isNameChanged && { name: value.name }), // Отправляем `name`, только если оно изменилось
      ...(user?.role === EUserRole.Agent && {
        profile: {
          businessType: 'enterpreter',
        },
      }),
    };

    try {
      setLoader(true);
      const updatedUser = await requestFunction(
        payload as IPatchUpdateAgentPayload,
      );
      setUser(updatedUser);
      addNotification(t('update_success'), 'success');
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid: isValid && isNameChanged, // Теперь кнопка будет активна только если имя изменилось
    watch,
  };
};

export default useFormEditAccount;
