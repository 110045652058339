import * as zod from 'zod';
import i18next from 'i18next';

const createMinLengthErrorMessage = (length: number) =>
  i18next.t('error_min_length', { length });

const zodStringWithMin = (length: number) =>
  zod.string().min(length, createMinLengthErrorMessage(length));

export const editAccountFormSchema = zod.object({
  name: zodStringWithMin(1).max(255),

  // email: zod.string().email(i18next.t('error_enter_valid_email')),
  // phone: zodStringWithMin(1),
});

export const changePasswordFormSchema = zod
  .object({
    currentPassword: zod.string(),
    newPassword: zod
      .string()
      .min(8, createMinLengthErrorMessage(8))
      .regex(
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/,
        i18next.t('error_latin_letters_only'),
      ),
    confirmNewPassword: zodStringWithMin(8),
  })
  .refine(
    (values) => {
      return values.newPassword === values.confirmNewPassword;
    },
    {
      message: i18next.t('error_passwords_must_match'),
      path: ['confirmNewPassword'],
    },
  );

export const changeEmailFormSchema = zod.object({
  email: zod.string().email(i18next.t('error_enter_valid_email')),
});
