import React from 'react';
import { useTranslation } from 'react-i18next';

import useGlobalModalStore from '../../../../../common/stores/useGlobalModalStore';

import ModalTemplate from '../../../../../common/components/Modal/ModalTemplate';
import Button from '../../../../../common/components/Button/Button';

import { ReactComponent as SuccessIcon } from '../../../../../common/assets/icons/thumbUpIcon.svg';

const ModalBody = () => {
  const { t } = useTranslation();

  const { onClose } = useGlobalModalStore();

  const handleLoginRedirect = () => {
    onClose();
    window.location.href = '';
  };

  return (
    <div className="flex items-end pt-[20px]">
      <Button onClick={handleLoginRedirect} size="small">
        {t('change_password_success_button')}
      </Button>
    </div>
  );
};

const ChangePasswordSuccessModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      icon={<SuccessIcon />}
      title={t('change_password_success_title')}
      description={t('change_password_success_description')}
      body={<ModalBody />}
    />
  );
};

export default ChangePasswordSuccessModal;
