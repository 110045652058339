import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../common/utils/helpers';

import { ReactComponent as EditIcon } from '../../../common/assets/icons/editIcon.svg';
import { ReactComponent as BellIcon } from '../../../common/assets/icons/bellIcon.svg';

interface IPropertySearchesListItemProps {
  searchItem: any;
  flatCategories: any;
  processFields: any;
  handleEditSearch: any;
}

const PropertySearchesListItem: React.FC<IPropertySearchesListItemProps> = ({
  searchItem,
  flatCategories,
  processFields,
  handleEditSearch,
}) => {
  const {
    id,
    name,
    filters = {},
    searchResultCount,
    notificationInterval,
  } = searchItem || {};

  // Добавляем защитные проверки для всех вложенных свойств
  const { categories = [], fields = {}, priceFrom, priceTo } = filters;
  const safeFields = fields || {};

  const { t } = useTranslation();

  // Обрабатываем fields только если они существуют
  const { description } = processFields(safeFields);

  const getStringNotificationInterval = useCallback(
    (interval: number | null) => {
      switch (interval) {
        case 1:
          return t('once_an_hour');
        case 24:
          return t('once_a_day');
        case 168:
          return t('once_a_week');
        case 0:
        case null:
          return t('dont_notify_me');
        default:
          return interval
            ? t('once_every_x_hours', { notificationInterval: interval })
            : t('never');
      }
    },
    [t],
  );

  // Безопасный поиск категории
  const category = flatCategories?.find(
    (cat: any) => cat.id === categories?.[0],
  );

  // Формируем название категории с проверками
  const categoryNameArray = category?.name?.split?.('.')?.slice(0, -1) || [];
  const parts = [
    t('for'),
    categoryNameArray[0] ? t(categoryNameArray[0]) : null,
    categoryNameArray[1]
      ? capitalizeFirstLetter(t(categoryNameArray[1]))
      : null,
    categoryNameArray[2]
      ? capitalizeFirstLetter(t(categoryNameArray[2]))
      : null,
    category?.title,
  ].filter(Boolean);

  const categoryString = parts.join(' ');

  // Формируем строку цены
  const hasPrice = priceFrom || priceTo;
  const priceParts = hasPrice
    ? [
        `${t('price')}:`,
        priceFrom ? `${priceFrom} Kc` : null,
        priceTo ? `- ${priceTo} Kc` : '- ∞ Kc',
      ].filter(Boolean)
    : [];

  const priceString = priceParts.join(' ');

  // Формируем итоговую строку данных
  const dataParts = [
    priceString,
    description && (priceString ? '. ' : ''),
    description,
  ].filter(Boolean);

  const dataString = dataParts.join('');

  const handleEditSearchClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleEditSearch(id, name, notificationInterval);
  };

  return (
    <div key={id}>
      <div className="flex items-center gap-[15px] mb-[5px]">
        <div className="text-heading-2 text-heading">
          {capitalizeFirstLetter(name || t('unnamed_search'))}
        </div>
        <button onClick={handleEditSearchClick}>
          <EditIcon />
        </button>
      </div>

      <div className="flex flex-col gap-[10px]">
        {categoryString && <div className="text-accent">{categoryString}</div>}

        <div className="flex items-center gap-[10px]">
          <BellIcon />
          <div>
            {getStringNotificationInterval(notificationInterval ?? null)}
          </div>
        </div>

        {typeof searchResultCount === 'number' && (
          <div>
            {searchResultCount} {t('listings_found')}
          </div>
        )}

        {dataString && <div>{dataString}</div>}
      </div>
    </div>
  );
};

export default PropertySearchesListItem;
