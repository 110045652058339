import React from 'react';

import usePropertyFavorite from '../../hooks/usePropertyFavorite';

import LikeIcon from '../../assets/icons/LikeIcon';

const FavoriteButton = ({
  id,
  className,
  isFavorite = false,
  refetch,
}: {
  id: string;
  className?: string;
  isFavorite?: boolean;
  refetch?: () => void;
}) => {
  const { toggleFavorite } = usePropertyFavorite();

  const handleClick = async (event: any) => {
    event.preventDefault();

    await toggleFavorite(id, isFavorite);
    if (refetch) {
      refetch();
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`absolute transition-opacity duration-500 ${isFavorite ? 'opacity-80' : 'opacity-0'}  group-hover:opacity-60 group-hover:hover:opacity-100 ${className || 'top-[7px] right-[7px]'} flex-center w-[32px] h-[32px] rounded-[10px] ${isFavorite ? 'bg-primary' : 'bg-[#A9A9A9]'}`}
    >
      <LikeIcon width={20} height={20} />
    </div>
  );
};

export default FavoriteButton;
