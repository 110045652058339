import React from 'react';
import { useTranslation } from 'react-i18next';

import useAuthStore from '../../common/stores/useAuthStore';
import useGlobalModalStore from '../../common/stores/useGlobalModalStore';

import ImageWithFallback from '../../common/components/Image/ImageWithFallback';
import ProfileTip from '../../common/components/ProfileTip/ProfileTip';
import Input from '../../common/components/Input/Input';
// import PhoneInput from '../../common/components/PhoneInput/PhoneInput';
// import Textarea from '../../common/components/Textarea/Textarea';
import Button from '../../common/components/Button/Button';

import useFormEditAccount from '../../features/profile/hooks/useFormEditAccount';

import AvatarModal from '../../features/profile/components/modals/AvatarModal/AvatarModal';
import ChangeEmailModalForm from '../../features/profile/components/modals/ChangeEmailModalForm/ChangeEmailModalForm';
import ConfirmEmailModalForm from '../../features/profile/components/modals/ConfirmEmailModalForm/ConfirmEmailModalForm';

import EmailIcon from '../../common/assets/icons/EmailBigIcon';
import EditBigIcon from '../../common/assets/icons/EditBigIcon';

import { ReactComponent as AvatarIcon } from '../../common/assets/icons/avatarIcon.svg';

const MyProfilePage = () => {
  const { t } = useTranslation();

  const { user } = useAuthStore();
  const { setIsOpen, setChildren } = useGlobalModalStore();

  const { control, handleSubmit, isValid } = useFormEditAccount();

  const openAvatarModal = (e: any) => {
    e.preventDefault();
    setChildren(<AvatarModal />);
    setIsOpen(true);
  };

  const openChangeEmailModal = (e: any) => {
    e.preventDefault();
    setChildren(<ChangeEmailModalForm />);
    setIsOpen(true);
  };

  const openConfirmEmailModal = (e: any) => {
    e.preventDefault();
    setChildren(<ConfirmEmailModalForm />);
    setIsOpen(true);
  };

  return (
    <div className="grid grid-cols-[1fr_342px] pb-[45px]">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-[30px] px-[25px]"
      >
        <h3 className="text-heading-5 text-heading">
          {t('edit_personal_data')}
        </h3>
        <div className="flex gap-[30px]">
          <div onClick={openAvatarModal} className="cursor-pointer">
            {!user?.avatar ? (
              <div className="flex-center rounded-full w-[100px] h-[100px] bg-secondary">
                <AvatarIcon />
              </div>
            ) : (
              <ImageWithFallback
                className="rounded-full w-[100px] h-[100px]"
                src={user.avatar.url}
                alt="avatar"
                width={100}
                height={100}
              />
            )}
          </div>
          <div className="flex-1">
            <Input
              label={t('full_name')}
              name="name"
              control={control}
              placeholder={t('enter_full_name')}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[5px] max-w-[420px]">
          <Input
            label={t('email')}
            name="email"
            control={control}
            disabled
            placeholder={t('email_placeholder')}
          />
          <div className="flex items-center gap-[35px]">
            <button
              className="text-body-small text-accent"
              onClick={openChangeEmailModal}
              type="button"
            >
              {t('change_email_address')}
            </button>
            {!user?.isEmailConfirmed && (
              <button
                className="text-body-small text-accent"
                onClick={openConfirmEmailModal}
                type="button"
              >
                {t('verify_email_address')}
              </button>
            )}
          </div>
        </div>
        {/*<div className="max-w-[420px]">*/}
        {/*  <PhoneInput*/}
        {/*    label={t('phone_number')}*/}
        {/*    name="phone"*/}
        {/*    control={control}*/}
        {/*    // defaultCountry="CZ"*/}
        {/*    placeholder={t('phone_number_placeholder')}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <Textarea*/}
        {/*    label={t('about_me')}*/}
        {/*    name="description"*/}
        {/*    control={control}*/}
        {/*    placeholder={t('client_about_placeholder')}*/}
        {/*    className="h-[177px] rounded-[15px] p-[16px]"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="flex justify-end">
          <Button withCooldown disabled={!isValid} type="submit">
            {t('update_profile')}
          </Button>
        </div>
      </form>
      <div className="flex flex-col gap-[25px]">
        <ProfileTip
          icon={<EditBigIcon />}
          text={t('personal_help_message_1')}
        />
        <ProfileTip icon={<EmailIcon />} text={t('personal_help_message_2')} />
      </div>
    </div>
  );
};

export default MyProfilePage;
